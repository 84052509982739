import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import logoimage from "../images/logo.png";
import nextarrow from "../images/nextarrow.png";
import backarrow from "../images/backarrow.png";
import infobtn from "../images/infobtn.png";
import $ from "jquery";
import Swal from "sweetalert2";
import InputMask from "react-input-mask";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
// import { createNewData } from "../reducers/Carrier";
// import {createNewDatazoo } from "../reducers/Datazoo";
// import { useDispatch, useSelector } from 'react-redux';
// import { validateIrd } from "./IRDValidatore";
import { datazoo } from "../components/Configuration";

import axios from "axios";
import swal from "sweetalert";
const ProvidetaxTwo = ({ setForm, formData, navigation }) => {
  let city = sessionStorage.getItem("locality");
  let postal_code = sessionStorage.getItem("postal_code");
  let country = sessionStorage.getItem("country");
  let region = sessionStorage.getItem("sublocality_level_1");
  let route = sessionStorage.getItem("route");
  let street_number = sessionStorage.getItem("street_number");
  let subpremise = sessionStorage.getItem("subpremise");
  let addressLine1 = street_number + " " + route;

  const { entityCompanyOne, entityCompanyOne1, entityCompanyOne2 } = formData;
  const { previous, next, go } = navigation;
  const [selectdd, setSelect] = useState();
  // useEffect(() => {
  //   $(document).ready(function() {
  //     $("#hide").click(function() {
  //       $("#showmy").show();
  //       $("#hidemy").hide();
  //     });

  //     $("#show").click(function() {
  //       $("#hidemy").show();
  //       $("#showmy").hide();
  //     });
  //     $(".flagsave").click(function() {
  //       let country = $(
  //         ".counrtyselext #select_flag_button > span > span"
  //       ).text();
  //       localStorage.setItem("countryOfBirth", country);
  //     });
  //     $(document).ready(function() {
  //       let benIsNZCitizen = formData.benIsNZCitizen
  //         ? formData.benIsNZCitizen
  //         : "true";
  //       document.getElementsByClassName("toogles").value = benIsNZCitizen;
  //       $(".yesno").click(function() {
  //         benIsNZCitizen = $(this).val();
  //         formData.benIsNZCitizen = benIsNZCitizen;
  //       });
  //     });
  //     $(".flagsave").click(function() {
  //       let countryOfResidence = $(
  //         ".counrtyselext2 #select_flag_button > span > span"
  //       ).text();
  //       localStorage.setItem("countryOfResidence", countryOfResidence);
  //     });
  //   });
  //   $(".toogles").click(function() {
  //     $(".toogles").removeClass("actives");
  //     $(this).addClass("actives");
  //   });
  // });

  useEffect(() => {
    $(".toogles").click(function () {
      $(".toogles").removeClass("actives");
      $(this).addClass("actives");
    });
    $(".toogles1").click(function () {
      $(".toogles1").removeClass("actives");
      $(this).addClass("actives");
    });
    // amlcheck();
    // amlcheckli();
  }, []);

  if (!formData.pir) {
    formData.pir = "Select";
  }

  function verifypir() {
    Swal.fire({
      html:
        "If you are unsure of your PIR rate, please visit this " +
        '<a href="https://www.ird.govt.nz/roles/portfolio-investment-entities/find-my-prescribed-investor-rate" target="_blank">IRD website</a>' +
        "  to determine your correct PIR"
    });
  }

  function previouss() {
    go("Five");
  }
  function handleClick() {
    // var btnValss = $(".postalAddressButton").hasClass("actives");

    if (formData.is_firstTimeKWS == null) {
      $(".alldata")
        .html("Please select Yes or No")
        .css("color", "red");
    } else if (
      (formData.kwsProvider == "" ||
        formData.kwsProvider == "undefined" ||
        formData.kwsProvider == null) &&
      formData.is_firstTimeKWS === "false"
    ) {
      $(".kwsProvider")
        .html("Please enter your current provider name")
        .css("color", "red");
    } else if (
      formData.contributionPercent == "Select" ||
      formData.contributionPercent == null ||
      formData.contributionPercent == "" ||
      formData.contributionPercent == "undefined"
    ) {
      $(".contributionPercent")
        .html("Please select your contribution method")
        .css("color", "red");
    } else if (formData.is_additionalVoluntary == null) {
      $(".allsecond")
        .html("Please select Yes or No")
        .css("color", "red");
    } else if (
      (formData.additionalVoluntaryFrequency == "Select Frequency" ||
        formData.additionalVoluntaryFrequency == "" ||
        formData.additionalVoluntaryFrequency == null ||
        formData.additionalVoluntaryFrequency == undefined) &&
      formData.is_additionalVoluntary === "true"
    ) {
      $(".additionalVoluntaryFrequency")
        .html("Please select additional voluntary frequency")
        .css("color", "red");
    } else if (
      (formData.additionalVoluntaryAmount == "" ||
        formData.additionalVoluntaryAmount == null ||
        formData.additionalVoluntaryAmount == undefined) &&
      formData.is_additionalVoluntary === "true"
    ) {
      $(".additionalVoluntaryAmount")
        .html("Please enter additional voluntary amount")
        .css("color", "red");
    } else if (
      (formData.primarySourceOfVoluntary == "" ||
        formData.primarySourceOfVoluntary == "Select" ||
        formData.primarySourceOfVoluntary == null ||
        formData.primarySourceOfVoluntary == undefined) &&
      formData.is_additionalVoluntary === "true"
    ) {
      $(".primarySourceOfVoluntary")
        .html("Please select source of contributions")
        .css("color", "red");
    } else if (
      formData.primarySourceOfVoluntary == "Other" &&
      (formData.primarySourceOfVoluntaryOther == "" ||
        formData.primarySourceOfVoluntaryOther == null ||
        formData.primarySourceOfVoluntaryOther == undefined)
    ) {
      $(".primarySourceOfVoluntaryOther")
        .html("Please provide details")
        .css("color", "red");
    } else {
      go("Six");
    }
  }

  function setPass(e) {
    if (e.length !== 9) {
      $(".eerror")
        .html(
          "Your IRD number must be in the format 123-456-789. If your IRD number only has 2 digits at the start, please enter a 0 before the first digit. "
        )
        .css("color", "gray");
    }
  }

  function removeError() {
    $(".error").html("");
  }
  const [options, setOption] = useState();

  function otherOption(event) {
    setOption(event.target.value);
  }
  const yesNoPop = () => {
    Swal.fire({
      text:
        "By joining KiwiSaver for the first time, you will be required to make contributions from your pay for at least 12 months if you are an employee.",
      allowOutsideClick: false
    });
  };
  // formData.is_firstTimeKWS === "true" ? Swal.fire("hiii"):""

  // function DropDown (el) {
  //   this.dd = el;
  //   this.placeholder = this.dd.children("span");
  //   this.opts = this.dd.find("ul.drop li");
  //   this.val = "";
  //   this.index = -1;
  //   this.initEvents();
  // }

  // DropDown.prototype = {
  //   initEvents: function() {
  //     var obj = this;
  //     obj.dd.on("click", function(e) {
  //       e.preventDefault();
  //       e.stopPropagation();
  //       $(this).toggleClass("active");
  //     });
  //     obj.opts
  //       .on("click", function() {
  //         var opt = $(this);
  //         obj.val = opt.text();
  //          formData.contributionPercent =  obj.val
  //         obj.index = opt.index();
  //         obj.placeholder.text(obj.val);
  //         opt.siblings().removeClass("selected");
  //         opt.filter(':contains("' + obj.val + '")').addClass("selected");

  //       })
  //       .change();
  //   },
  //   getValue: function() {
  //     return this.val;
  //   },
  //   getIndex: function() {
  //     return this.index;
  //   }
  // };

  // $(function() {
  //   // create new variable for each menu
  //   var dd1 = new DropDown($("#noble-gases"));
  //   // var dd2 = new DropDown($('#other-gases'));
  //   $(document).click(function() {
  //     // close menu on document click
  //     $(".wrap-drop").removeClass("active");
  //   });
  // });
  const selectsss = e => {
    setForm(e);
    removeError(e);

    setSelect(e.target.value);
    formData.SelectValue = e.target.value;
  };
  //  useEffect(()=>{
  //   setSelect("select")
  //  },[])
  // alert(formData.SelectValue);
  return (
    <div className="form provide_tax">
      <div className="headerlogo">
        <img src={logoimage} alt="" />
        <div className="headercontent">
          <h3 className="form_heading">
            Let us know how you'll use your Account
          </h3>
        </div>
      </div>
      <div className="body_content margin-25">
        <div>
          <Container>
            <Row>
              <Col lg={12}>
                <div className="col_innertext">
                  <label>Are you joining KiwiSaver for the first time?</label>
                </div>
              </Col>
              <Col lg={12}>
                <div className="button_group">
                  <div class="switch-field nospace">
                    <div className="check_YN_Bank swtich11">
                      <input
                        type="radio"
                        id="radio-one"
                        className="yesno"
                        name="is_firstTimeKWS"
                        value="true"
                        onChange={e => {
                          setForm(e);
                          removeError();
                          formData.kwsProvider = null;
                          yesNoPop();
                        }}
                      />
                      <label
                        for="radio-one"
                        className={`toogles postalAddressButton ${
                          // typeof formData.is_firstTimeKWS === "undefined" ||
                          formData.is_firstTimeKWS === "true" ? "actives" : null
                          }`}
                        id="show">
                        Yes
                      </label>
                      <input
                        type="radio"
                        id="radio-two"
                        name="is_firstTimeKWS"
                        value="false"
                        onChange={e => {
                          setForm(e);
                          removeError();
                        }}
                      />
                      <label
                        for="radio-two"
                        className={`toogles postalAddressButtons ${formData.is_firstTimeKWS === "false"
                            ? "actives"
                            : null
                          }`}
                        id="hide">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </Col>

              <Col md={12}>
                <span className="error yes_No alldata" />
              </Col>
              {formData.is_firstTimeKWS === "false" ? (
                <div style={{ width: "100%" }}>
                  <Col lg={12}>
                    <label>Who is your existing KiwiSaver Provider?</label>
                  </Col>
                  <Col md={6}>
                    <input
                      type="text"
                      onChange={e => {
                        setForm(e);
                        removeError();
                      }}
                      placeholder="Enter your current provider name"
                      name="kwsProvider"
                      value={formData.kwsProvider}
                    />
                    <span className="error kwsProvider" />
                  </Col>
                </div>
              ) : (
                ""
              )}

              {/* date-08-12-22 start */}
              <Col lg={12}>
                <div className="col_innertext">
                  <label>
                    How do you intend to contribute to your KiwiSaver account?
                  </label>
                </div>
              </Col>
              <Col lg={12}>
                <div class="wrap-drop" id="noble-gases">
                  {/* <select class="drop" onChange={(e)=>selectsss(e)}  */}
                  {/* > */}
                  <select
                    class="drop"
                    name="contributionPercent"
                    onChange={e => {
                      setForm(e);
                      removeError();
                    }}
                    value={formData.contributionPercent}>
                    <option>Select</option>
                    <option>I'm an employee and I'm contributing 3% </option>
                    <option> I'm an employee and I'm contributing 4% </option>
                    <option> I'm an employee and I'm contributing 6% </option>
                    <option> I'm an employee and I'm contributing 8%</option>
                    <option> I'm an employee and I'm contributing 10%</option>
                    <option> I’m not contributing</option>
                    <option> I'm self employed</option>
                    <option>I'm on a savings suspension </option>
                  </select>
                  <span className="error contributionPercent" />
                </div>
              </Col>
              {/* date-08-12-22 end */}
              <Col lg={12}>
                <div className="col_innertext">
                  <label>
                    Do you intend on making any additional voluntary
                    contributions into your KiwiSaver account over and above any
                    employer / employee contributions?
                  </label>
                </div>
              </Col>
              <Col lg={12}>
                <div className="button_group">
                  <div class="switch-field nospace">
                    <div className="check_YN_Bank swtich11">
                      <input
                        type="radio"
                        id="radio-one2"
                        className="yesno"
                        name="is_additionalVoluntary"
                        value="true"
                        onChange={e => {
                          setForm(e);
                          removeError();
                        }}
                        onClick={e => {
                          removeError(e);
                        }}
                      />
                      <label
                        for="radio-one2"
                        className={`toogles1 postalAddressButton ${
                          // typeof formData.is_additionalVoluntary === "undefined" ||
                          formData.is_additionalVoluntary === "true"
                            ? "actives"
                            : null
                          }`}
                        id="show">
                        Yes
                      </label>
                      <input
                        type="radio"
                        id="radio-two2"
                        name="is_additionalVoluntary"
                        value="false"
                        onChange={e => {
                          setForm(e);
                          removeError();
                          formData.additionalVoluntaryFrequency = null;
                          formData.additionalVoluntaryAmount = null;
                          formData.primarySourceOfVoluntary = null;
                        }}
                        onClick={e => {
                          removeError(e);
                        }}
                      />
                      <label
                        for="radio-two2"
                        className={`toogles1 postalAddressButtons ${formData.is_additionalVoluntary === "false"
                            ? "actives"
                            : null
                          }`}
                        id="hide">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={12}>
                <span className="error yes_No allsecond" />
              </Col>
              <>
                {formData.is_additionalVoluntary === "true" ? (
                  <>
                    <div className="container">
                      <div className="row">
                        <Col lg={12}>
                          {" "}
                          <label>
                            How much and how often do you intend to make
                            additional voluntary contributions to your KiwiSaver
                            account?
                          </label>
                        </Col>
                        <Col md={6}>
                          <select
                            name="additionalVoluntaryFrequency"
                            onChange={e => {
                              setForm(e);
                              removeError();
                            }}
                            value={formData.additionalVoluntaryFrequency}>
                            <option>Select Frequency</option>
                            <option>Weekly</option>
                            <option>Fortnightly</option>
                            <option>Monthly</option>
                            <option>Six-monthly</option>
                            <option>Infrequently - Now & then </option>
                          </select>
                          <span className="error additionalVoluntaryFrequency" />
                        </Col>
                        <Col md={6}>
                          {" "}
                          <input
                            type="number"
                            name="additionalVoluntaryAmount"
                            placeholder="Enter $ amount"
                            onwheel="return false;"
                            onWheel={e => e.target.blur()}
                            onChange={e => {
                              setForm(e);
                              removeError(e);
                            }}
                            value={formData.additionalVoluntaryAmount}
                          />
                          <span className="error additionalVoluntaryAmount" />
                        </Col>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
              <div>
                {formData.is_additionalVoluntary === "true" ? (
                  <div style={{ width: "100%" }}>
                    <Col lg={12}>
                      {" "}
                      <label>
                        What will be the primary source of your additional
                        voluntary contributions to your KiwiSaver account?
                      </label>
                    </Col>
                    <Col md={6}>
                      <select
                        name="primarySourceOfVoluntary"
                        onChange={e => {
                          setForm(e);
                          removeError();
                          if (e.target.value != "Select") {
                            formData.primarySourceOfVoluntaryOther = null;
                          }
                        }}
                        value={formData.primarySourceOfVoluntary}>
                        <option>Select</option>
                        <option>Wages</option>
                        <option>Business earnings</option>
                        <option> Transfers from family</option>
                        <option>Rental income</option>
                        <option>Other</option>
                      </select>
                      <span className="error primarySourceOfVoluntary" />
                      <div>
                        {formData.primarySourceOfVoluntary == "Other" ? (
                          <>
                            <input
                              type="text"
                              onChange={e => {
                                setForm(e);
                                removeError();
                              }}
                              name="primarySourceOfVoluntaryOther"
                              value={formData.primarySourceOfVoluntaryOther}
                              placeholder="Please specify"
                            />
                            <span className="error primarySourceOfVoluntaryOther" />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Row>

            <Row>
              <Col md={12}>
                <div className="footer">
                  <Button
                    type="button"
                    className="preview trans_btn"
                    onClick={previouss}>
                    <img src={backarrow} className="arrow_size" alt="arrow" />{" "}
                    Back
                  </Button>
                  <Button
                    type="button"
                    className="next grad_btn"
                    onClick={handleClick}>
                    Next
                    <img
                      src={nextarrow}
                      className="arrow_size"
                      alt="arrow"
                    />{" "}
                  </Button>
                  {/* :
                  <button
                  type="button"
                  className="next grad_btn"
                
                  >
                    {value}
                  Please Wait...
                  
                </button>

                  } */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default ProvidetaxTwo;
