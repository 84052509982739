import React, { useEffect , useState } from "react";
import { Container, Row, Col,Button } from "react-bootstrap";
import logoimage from "../images/logo.png";
import nextarrow from "../images/nextarrow.png";
import backarrow from "../images/backarrow.png";
import infobtn from "../images/infobtn.png";
import $ from "jquery";
import Swal from "sweetalert2";
import InputMask from "react-input-mask";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
// import { createNewData } from "../reducers/Carrier";
// import {createNewDatazoo } from "../reducers/Datazoo";
// import { useDispatch, useSelector } from 'react-redux';
// import { validateIrd } from "./IRDValidatore";
import { datazoo } from "../components/Configuration";


import axios from "axios";
import Providetax from "./Providetax";
const Five = ({ setForm, formData, navigation }) => {
  // const { irdNumber } = formData;
  // const createData = useSelector(state => state.Carrier)
 
  // const createData1 = useSelector(state => state.Datazoo)

  // const dispatch = useDispatch() 
 
//   console.log("createData",createData.SuccMessages)
// console.log("createData1",createData1.SuccMessagess)
// console.log("formData.addresscheck",formData.addresscheck=createData.SuccMessages)
// formData.addresscheck=createData.SuccMessages
// console.log("formData.idcheck",formData.idcheck=createData1.SuccMessagess)
// formData.idcheck=createData1.SuccMessagess
// if ((createData.SuccMessages== false)||(createData.SuccMessages==true)&&(createData.SuccMessages!= null)) {
//   console.log("formData.addresscheck",formData.addresscheck=createData.SuccMessages)
// }

  let city = sessionStorage.getItem("locality");
  let postal_code = sessionStorage.getItem("postal_code");
  let country = sessionStorage.getItem("country");
  let region = sessionStorage.getItem("sublocality_level_1");
  let route = sessionStorage.getItem("route");
  let street_number = sessionStorage.getItem("street_number");
  let subpremise = sessionStorage.getItem("subpremise");
  let addressLine1 = street_number + " " + route ;

  // let CreateUser = () => {
  //   var postData = {
  //     firstname: formData.firstName,
  //     middlename: formData.middleName == null? "" :formData.middleName,
  //     lastname: formData.lastName == null ?"":formData.lastName,
  //     dateofbirth:formData.dateOfBirth,
  //     addressLine1: addressLine1 == "null null" ? formData.fullAddress: addressLine1,
  //     region: region,
  //     city: city,
  //     postalCode: postal_code,

  //   }
  //   //
   
   
    
  //   dispatch(createNewData(postData))
    

  // }

  //

  // let CreateUserdata = () => {
  //   let postData;
  //   if (formData.identificationType === "NZ Driver Licence") {
  //     postData = {
  //       firstname: formData.firstName,
  //       middlename: formData.middleName == null? "" :formData.middleName,
  //       lastname: formData.lastName == null ?"":formData.lastName,
  //       idexpiry: formData.expiryDate,
  //       idnumber: formData.identificationNumber,
  //       idversion: formData.version,
  //       dateOfBirth:formData.dateOfBirth,
  //       //gender: formData.gender,
  //       addressLine1:addressLine1 == "null null" ? formData.fullAddress: addressLine1,
  //       idtype: "DL",


        
  //     }
  //   } else if (formData.identificationType === "NZ Passport") {
  //     postData = {
  //       firstname: formData.firstName,
  //       middlename: formData.middleName == null ? "" :formData.middleName,
  //       lastname: formData.lastName == null ?"":formData.lastName,
  
  //       idexpiry: formData.expiryDate,
  
  //       dateofbirth:formData.dateOfBirth,
  //       // idversion: formData.version,
  //       idnumber: formData.identificationNumber,
  
  //      addressLine1:addressLine1 == "null null" ? formData.fullAddress: addressLine1,
  //      region: region,
  //      city: city,
  //      postalCode: postal_code,
  //       idtype: "PP",
      
  
  //     }
  //   }
    
  //   dispatch(createNewDatazoo(postData))

  // }
  // useEffect(() => {
  //   CreateUser()
  //   CreateUserdata()
  // }, [])
  

  const { previous, next,go } = navigation;
  useEffect(() => {
    $(document).ready(function () {
      $("#hide").click(function () {
        $("#showmy").show();
        $("#hidemy").hide();
      });

      $("#show").click(function () {
        $("#hidemy").show();
        $("#showmy").hide();
      });
      $(".flagsave").click(function () {
        let country = $(
          ".counrtyselext #select_flag_button > span > span"
        ).text();
        localStorage.setItem("countryOfBirth", country);
      });
      $(document).ready(function () {
        let benIsNZCitizen = formData.benIsNZCitizen
          ? formData.benIsNZCitizen
          : "true";
        document.getElementsByClassName("toogles").value = benIsNZCitizen;
        $(".yesno").click(function () {
          benIsNZCitizen = $(this).val();
          formData.benIsNZCitizen = benIsNZCitizen;
        });
      });
      $(".flagsave").click(function () {
        let countryOfResidence = $(
          ".counrtyselext2 #select_flag_button > span > span"
        ).text();
        localStorage.setItem("countryOfResidence", countryOfResidence);
      });
    });
    $(".toogles").click(function () {
      $(".toogles").removeClass("actives");
      $(this).addClass("actives");
    });
  });

  useEffect(() => {
    // amlcheck();
    // amlcheckli();
   
  }, []);
  
   if(!formData.pir ) {(formData.pir = "Select")}  ;
  
  function verifypir() {
    Swal.fire({
      html:
        "If you are unsure of your PIR rate, please visit this " +
        '<a href="https://www.ird.govt.nz/roles/portfolio-investment-entities/find-my-prescribed-investor-rate" target="_blank">IRD website</a>' +
        "  to determine your correct PIR"
    });
  }
//  console.log("formData.irdNumber",formData.irdNumber)
  function handleClick() {
    // console.log("formData.irdNumber.length111111",formData.irdNumber.length)
    // console.log("formData.irdNumber",formData.irdNumber.length != 9 ? "mohan" :"tyagi")
    var btnValss = $(".postalAddressButton").hasClass("actives");
    if (
      !btnValss &&
      (typeof formData.irdNumber == "undefined" ||
        formData.irdNumber === "" ||
        formData.irdNumber == null)
    ) {
      $(".irdNumbers").html("Please enter your IRD number");
    } else if (formData.irdNumber.length < 11) {
      $(".irdNumbers")
        .html(
          "Your IRD number must be 9 digits (add 0 at the start if necessary)"
        )
        .css("color", "red");
    } else if (!(formData.irdNumber)) {
      // else if (!validateIrd(formData.irdNumber)) {
      $(".irdNumbers")
        .html("Please enter a valid IRD number")
        .css("color", "red");
    }
    else if (
      formData.pir == "Select" ||
      formData.pir == null ||
      formData.pir == "" ||
      formData.pir == "undefined"
      ) {
      // else if (!validateIrd(formData.irdNumber)) {
      $(".select_error")
        .html("Please select your PIR rate")
        .css("color", "red");
    }
      else {
        // alert(formData.isSix );
        if(formData.isSix == true){
          formData.contributionPercent = null;

          go("Providetax")
      
        }else{
          go("ProvidetaxTwo")
        }
        
      }
  }
console.log("formData.dateofbirth11111111:",formData.dateofbirth)
  function setPass(e) {
    if (e.length !== 9) {
      $(".eerror")
        .html(
          "<span>Your IRD number must be in the format 123-456-789. If your IRD number only has 2 digits at the start, please enter a 0 before the first digit.</span> "
        )
        .css("color", "gray");
    }

  } 
  function removeError() {
    $(".error").html("");
  }
  
  return (
    <div className="form">
      <div className="headerlogo">
        <img src={logoimage} alt="" />
        <div className="headercontent">
          <h3 className="form_heading">Provide your tax details</h3>
        </div>
      </div>
      <div className="body_content margin-25">
        <div >
          <Container>
            <Row>
              <Col md={6}>
                <label>IRD number</label>
                <InputMask autoComplete="off"
                  mask="999-999-999"
                  maskChar={null}
                  className="full"
                  type="text"
                  // value={irdNumber}
                  name="irdNumber"
                  // maskPlaceholder={null}
                  placeholder="xxx-xxx-xxx"
                  value={formData.irdNumber ? formData.irdNumber : ""}
                  onChange={e => {
                    setForm(e);
                    setPass(e);
                  }}
                  onClick={e => {
                    setPass(e);
                  }}
                />

                <span className="error irdNumbers eerror" />
                <span className="msg-show" />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <label>
                  Select your Prescribed Investor Rate (PIR)
                  <img
                    src={infobtn}
                    className="btn_icon"
                    alt="info"
                    onClick={verifypir}
                  />
                </label>
              </Col>
              <Col md={6}>
                <select
                  id="select_pir"
                  name="pir"
                  onChange={e => {
                    setForm(e);
                    removeError();
                   
                  }}
                  value={formData.pir ? formData.pir : ""}>
                  <option>Select</option>
                  <option>10.5%</option>
                  <option>17.5%</option>
                  <option>28%</option>
                 
                </select>
                {/* {value} */}
                <span className="error pirerror select_error" />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="footer">
                  <Button
                    type="button"
                    className="preview trans_btn"
                    onClick={previous}>
                    <img src={backarrow} className="arrow_size" alt="arrow" />{" "}
                    Back
                  </Button>
                  {/* {(value === true || value === false)&& (value1 === true || value1 === false)
                  ? */}
                    <Button
                    type="button"
                    className="next grad_btn"
                    onClick={handleClick}
                    >
                    Next
                    <img
                      src={nextarrow}
                      className="arrow_size"
                      alt="arrow"
                    />{" "}
                  </Button>
                  {/* :
                  <button
                  type="button"
                  className="next grad_btn"
                
                  >
                    {value}
                  Please Wait...
                  
                </button>

                  } */}
                
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Five;
