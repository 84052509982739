import axios from 'axios'
import { datazooapi1 } from '../components/Configuration'

const initialState = {
    loading: false,
    users: [],
    newUserData: {},
    error: '',
    errorMessages: null,
    SuccMessagess:null,
    updateSuccMessage:null,
}
export const UPDATE_NEWUSERDATA_REQUEST = "UPDATE_NEWUSERDATA_REQUEST";
export const UPDATE_NEWUSERDATA_SUCCESS = "UPDATE_NEWUSERDATA_SUCCESS";
export const UPDATE_NEWUSERDATA_FAILURE = "UPDATE_NEWUSERDATA_FAILURE";
export const UPDATE_NEWUSERDATA_ERRORMESSAGES = 'UPDATE_NEWUSERDATA_ERRORMESSAGES';

export const createUserErrorMessages = (error) => {
    return {
      type: UPDATE_NEWUSERDATA_ERRORMESSAGES,
      payload: error
    }
  }
export const createUserRequest = () => {
    return {
        type: UPDATE_NEWUSERDATA_REQUEST,
    }
}
export const createUserSuccess = (success) => {
    return {
        type: UPDATE_NEWUSERDATA_SUCCESS,
        payload: success
    }
}
export const createUserFailure = (error) => {
    return {
        type: UPDATE_NEWUSERDATA_FAILURE,
        payload: error
    }
}

//
export const userReducer = (state = initialState, action) => {
    switch(action.type) {
        
        // create user reducer
        case UPDATE_NEWUSERDATA_REQUEST:
            return {
                ...state,
                loading: true,
                error: ''
            }
        case UPDATE_NEWUSERDATA_SUCCESS:
        return {
            ...state,
            loading: false,
            SuccMessagess:action.payload,
            error: ''
        }    
        case UPDATE_NEWUSERDATA_FAILURE:
        return {
            ...state,
            loading: false,
             error: action.payload,
        } 
        case UPDATE_NEWUSERDATA_ERRORMESSAGES:
    return {
      ...state,
      loading: false,
      errorMessages: action.payload
    }    
        default: return state
    }        
}
export default userReducer;

export const createNewDatazoo = (postData) => {
    return (dispatch) => {
        dispatch(createUserRequest())
        axios.post(`${datazooapi1}`, postData ,  {
        })
        .then((response) => {  
            const newUserData = response.data
            // console.log("Responce",newUserData);
            if (newUserData.error) {
                dispatch(createUserErrorMessages(newUserData.message))
            } {
                dispatch(createUserSuccess(newUserData.verified))
            }
        })
        .catch(error => dispatch(createUserFailure(error.message)) )
    }
}
//https://ddyun0rizabgo.cloudfront.net