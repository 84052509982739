import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';


import App from './App';
import configureStore from './config/configureStore';


const rootElement = document.getElementById('root');
ReactDOM.render(
    <Provider store = {configureStore()}>
        <App />
    </Provider>
    
,
 rootElement);
