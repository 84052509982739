import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from 'redux-thunk'
import { composeWithDevTools } from "redux-devtools-extension";
import reducers from "../reducers";

export default function configureStore() {
  return createStore(
    combineReducers({
      ...reducers,
    }),
    composeWithDevTools(applyMiddleware(thunk))
  );
}
