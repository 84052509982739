import React, { useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import logoimage from "../images/logo.png";
import nextarrow from "../images/nextarrow.png";
import backarrow from "../images/backarrow.png";
import $ from "jquery";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import moment from "moment";
const Second = ({ setForm, formData, navigation }) => {
  const { previous, next } = navigation;
  const handleChange = (e) => {
    e.preventDefault();
  };
  function removeError() {
    $(".error").html("");
  }

  useEffect(() => {
    $(document).ready(function () {

      $(".letterOnly").keypress(function (e) {
        var regex = new RegExp(/^[a-zA-Z-`ā-ūĀ-Ū ]+$/);
        var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (regex.test(str)) {
          return true;
        } else {
          e.preventDefault();
          return false;
        }

      });

    });
    $(document).ready(function () {
      $(".numbers").keypress(function (e) {
        if (e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57)) {
          return false;
        }
      });
    });
    $(".digitsOnly").keypress(function (e) {
      var regex = new RegExp(/^[a-zA-Z\s/0-9]+$/);
      var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
      if (regex.test(str)) {
        return true;
      } else {
        e.preventDefault();
        return false;
      }
    });
  });

  useEffect(
    () => {
    },
    [formData]
  );

  const handleNext = () => {
    // function curd(selectedDate) {
    // var setDate = new Date(setDateformateToValid(selectedDate));
    var d = new Date();
    var pastYear = d.getFullYear() - 18;
    // d.setFullYear(pastYear);
    // if (d < setDate) {
    //   return true;
    // } else {
    //   return false;
    // }
    // }
    const date = document.querySelector("#date").value;

    formData.dateOfBirth = date;

    let nameExpression = /[a-z\-]+/;
    const dateValue = document.querySelector("#date").value;
    formData.dateOfBirth = dateValue;


    formData.title === "Mr"
      ? (formData.gender = "male")
      : (formData.gender = "female");

    if (
      typeof formData.title == "undefined" ||
      formData.title === "" ||
      formData.title === null ||
      formData.title === "Select"
    ) {
      $(".titleerror").html("Please select an option");
    } else if (
      typeof formData.fullName == "undefined" ||
      formData.fullName === "" ||
      formData.fullName === null
    ) {
      $(".fullNameerror").html("Please enter full name");
    } else if (!nameExpression.test(formData.fullName)) {
      $(".fullNameerror").html("Please enter only characters");
    }

    else if (
      typeof formData.dateOfBirth == "undefined" ||
      formData.dateOfBirth === "" ||
      formData.dateOfBirth === null
    ) {
      $(".doberror").html("Please enter your date of birth");
    }
    // else if ( formData.dateOfBirth > pastYear){
    //   $(".doberror").html("Please enter your date of birth");
    // }
    else if (curd(formData.dateOfBirth) || ((formData.dateOfBirth).split('/')[1]) > 12 || ((formData.dateOfBirth).split('/')[0]) > 31 || ((formData.dateOfBirth).split('/')[0]) == 0 || ((formData.dateOfBirth).split('/')[2]) < 1900) {
      $(".doberror").html("Please enter valid date of birth");
    }

    else {
      splitFullName(formData.fullName != null && formData.fullName != "" && formData.fullName != "undefined" ? formData.fullName.trim() : "");
      next();
    }
  };

  function curd(selectedDate) {
    var setDate = new Date(setDateformateToValid(selectedDate));
    var d = new Date();
    var pastYear = d.getFullYear() - 18;
    d.setFullYear(pastYear);
    if (d < setDate) {
      return true;
    } else {
      return false;
    }
  }


  function curd(selectedDate) {
    var setDate = new Date(setDateformateToValid(selectedDate));
    var d = new Date();
    var f = new Date();
    var pastYear = d.getFullYear() - 18;
    var pastYeart = f.getFullYear() - 65;
    d.setFullYear(pastYear);
    f.setFullYear(pastYeart);
    if (f < setDate) {

      formData.isSix = false
    } else {
      formData.isSix = true

    }
    if (d < setDate) {
      return true;
    } else {
      return false;
    }
  }
  function calculate_age(dob) {

    // var diff_ms = Date.now() - dob.getTime();
    // var age_dt = new Date(diff_ms); 

    // return Math.abs(age_dt.getUTCFullYear() - );

  }

  formData.dateofbirth = calculate_age(new Date(formData.dateOfBirth));
  console.log(calculate_age(new Date(formData.dateOfBirth)));

  function splitFullName(fn) {
    fn = fn.replace(/(^[\s]*|[\s]*$)/g, "");
    fn = fn.replace(/\s+/g, " ");
    var fnarr = fn.split(" ");
    formData.firstName = fnarr[0];
    if (fnarr.length >= 3) {
      formData.lastName = fnarr[fnarr.length - 1];
      fnarr.shift();
      fnarr.pop();
      fnarr = fnarr.toString().replace(/,/g, " ");
      formData.middleName = fnarr;
    } else if (fnarr.length === 3) {
      formData.middleName = fnarr[1];
      formData.lastName = fnarr[2];
    } else if (fnarr.length === 2) {
      formData.lastName = fnarr[1];
    }
  }

  function setDateformateToValid(date) {
    if (
      date !== null &&
      date !== "undefined" &&
      typeof date !== "undefined" &&
      date.includes("/")
    ) {
      let dat = date.split("/");
      dat = dat[2] + "-" + dat[1] + "-" + dat[0];
      return dat;
    }

  }
  useEffect(() => { }, [formData]);
  return (
    <>
      <div className="form">
        <div className="headerlogo">
          <img src={logoimage} alt="" />
          <div className="headercontent">
            <h3 className="form_heading">Tell us about yourself</h3>
          </div>
        </div>
        <div className="body_content margin-25">
          <div>
            <Container>
              <Row>
                <Col md={6}>
                  <label>Title</label>
                  <select
                    name="title"
                    onChange={e => {
                      setForm(e);
                      removeError();
                    }}
                    value={formData.title ? formData.title : ""}>
                    <option>Select</option>
                    <option>Mr</option>
                    <option>Mrs</option>
                    <option>Ms</option>
                    <option>Miss</option>
                  </select>
                  <span className="error titleerror" />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <label>Full name</label>
                  <input
                    autoComplete="off"
                    type="text"
                    maxlength="150"
                    name="fullName"
                    placeholder="Full name as it appears on ID"
                    onPaste={handleChange}
                    className="letterOnly"
                    value={formData.fullName ? formData.fullName : ""}
                    onChange={e => {
                      setForm(e);
                      removeError();
                    }}
                  />
                  <span className="error fullNameerror" />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <label>Preferred name (optional)</label>
                  <input
                    autoComplete="off"
                    type="text"
                    name="Preferred"
                    maxlength="50"
                    placeholder="Enter preferred name"
                    className="letterOnly"
                    onPaste={handleChange}
                    value={formData.Preferred}
                    onChange={e => {
                      setForm(e);
                      removeError();


                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <label>Date of birth</label>
                  <div className="custom_datepiker">
                    <SemanticDatepicker
                      autoComplete="off"
                      type="text"
                      className="datemy"
                      id="date"
                      clearIcon="close"
                      onChange={removeError}
                      name="date"
                      format="DD/MM/YYYY"
                      maxDate={new Date(moment().subtract(18, "years"))}
                      initialDate={new Date(moment().subtract(18, "years"))}
                      showDisabledMonthNavigation
                      allowOnlyNumbers
                      value={
                        formData.dateOfBirth
                          ? new Date(
                            setDateformateToValid(formData.dateOfBirth)
                          )
                          : null
                      }
                    />
                  </div>
                  <span className="error doberror" />
                  <span className="error titleerror12" />
                  <span className="error titleerror13" />
                  <span className="error titleerror14" />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="footer">
                    <Button
                      type="button"
                      className="preview trans_btn"
                      onClick={previous}>
                      <img src={backarrow} className="arrow_size" alt="arrow" />{" "}
                      Back
                    </Button>
                    <Button
                      type="button"
                      className="next grad_btn"
                      onClick={handleNext}>
                      Next
                      <img
                        src={nextarrow}
                        className="arrow_size"
                        alt="arrow"
                      />{" "}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );

};


export default Second;
