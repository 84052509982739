import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
let autoComplete;

const loadScript = (url, callback) => {

   
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
        script.onreadystatechange = function () {
            if (script.readyState === "loaded" || script.readyState === "complete") {
                script.onreadystatechange = null;
                callback();
            }
        };
    } else {
        script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(updateQuery, autoCompleteRef, updateApiAddress) {
    autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current,
        { componentRestrictions: { country: "nz" } }
    );
    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () =>
        handlePlaceSelect(updateQuery, updateApiAddress)
    );
}

var componentForm = {
    street_number: 'long_name',
    route: 'long_name',
    sublocality_level_1: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'long_name',
    country: 'long_name',
    postal_code: 'long_name'
};

async function handlePlaceSelect(updateQuery, updateApiAddress) {
    let newamp = {};
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);

    const y = addressObject.address_components;

    $(document).ready(function () {
        sessionStorage.clear();
        var map = new Map();
     
        for (i = 0; i < y.length; i++) {
           
            map[y[i].types[0]] = y[i].long_name;
            sessionStorage.setItem(y[i].types[0], y[i].long_name);
           
        }

        // var test = map;
    });
    for (var i = 0; i < addressObject.address_components.length; i++) {
        var addressType = addressObject.address_components[i].types[4];
        var val = addressObject.address_components[i][componentForm[addressType]];
        newamp[addressType] = val;
    }
    updateApiAddress(JSON.stringify(newamp))
}

function Googleaddress(props) {
    const [query, setQuery] = useState(props.value || "");
    const [googleApiAddress, setApiAddress] = useState(props.dataitem || {});
    const autoCompleteRef = useRef(null);
    function removeError() {
        sessionStorage.clear();

        $('.error').html('');
    }
    useEffect(() => {
        loadScript(
             `https://maps.googleapis.com/maps/api/js?key=AIzaSyDKuUGMz8-xYyc6t1YWUhygrrkB4-WAFeY&libraries=places`,
           // `https://maps.googleapis.com/maps/api/js?key=AIzaSyDKuUGMz8-xYyc6t1YWUhygrrkB4-WAFeY&libraries=places&callback=initAutocomplete`,
            () => handleScriptLoad(setQuery, autoCompleteRef, setApiAddress)
        );
    }, []);
    // google.maps.event.addDomListener(window,'load',initAutocomplete);
    const handleChange = (e) => {
        e.preventDefault();
      };
    return (
        <div className="search-location-input">
            <input
                 onPaste={handleChange}
                onKeyPress={removeError}
                ref={autoCompleteRef}
                onChange={event => setQuery(event.target.value)}
                value={query}
                defaultValue={props.value}
                placeholder={props.placeholder}
                className={props.className}
                id={props.id}
                data-val={googleApiAddress}
            />

        </div>
    );
}
//=initAutocomplete

export default Googleaddress;