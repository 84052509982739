import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import logoimage from "../images/logo.png";
import nextarrow from "../images/nextarrow.png";
import backarrow from "../images/backarrow.png";
import AxiosInstnce from "../axios";
import $ from "jquery";
const Six = ({
  formData,
  navigation,
}) => {
  const { previous, next, go } = navigation;
  var setRadioValue = e => {
    formData.investmentDFundType = e.target.value;
  };

  function previouss() {
    if (formData.isSix == true) {
      go("Providetax")
    } else {
      go("ProvidetaxTwo")
    }
  }
  const handleNext = () => {
    if (
      typeof formData.investmentDFundType == "undefined" ||
      formData.investmentDFundType === "" ||
      formData.investmentDFundType === null
    ) {
      $(".optionerror").html("Please select an investment fund");
    } else {
      next();
    }
  };
  useEffect(() => {

    $(".fund").on("click", function () {
      $(".fund").removeClass("current");
      $(this).addClass("current");
    });
  });

  return (
    <div className="form img_circle">
      <div className="headerlogo side">
        <img src={logoimage} alt="" />
        <div className="headercontent">
          <h3 className="form_heading">
            Confirm your selected KiwiSaver investment strategy
          </h3>
        </div>
      </div>
      <div className="body_content margin-24">
        <div>
          <Container>
            <Row className="justify-content: center">
              {/* <div className="d-flex align-items-center mb-4 mt-2 justify-content-center col-lg-12 reduse_mar">
                  <label className="radio mb-3">

                    <input
                      type="radio"
                      className="one"
                      name="investmentDFundType"
                      value="Liquidity Fund"
                      onClick={e => setRadioValue(e)}
                    />
                    <div className="flex-th LegacyPath1 ">
                      <div
                        className={
                          formData.investmentDFundType ===
                            "Liquidity Fund"
                            ? "current fund"
                            : "fund"
                        }>
                        <span />
                        <p>Liquidity Fund</p>
                      </div>
                    </div>
                  </label>
              </div> */}

              <div className="d-flex align-items-center mb-4 mt-2 row reduse_mar">
                <Col md={4}>
                  <label className="radio mb-3">

                    <input
                      type="radio"
                      className="one"
                      name="investmentDFundType"
                      value="Liquidity Fund"
                      onClick={e => setRadioValue(e)}
                    />
                    <div className="flex-th LegacyPath1 ">
                      <div
                        className={
                          formData.investmentDFundType ===
                            "Liquidity Fund"
                            ? "current fund"
                            : "fund"
                        }>
                        <span />
                        <p>Liquidity Fund</p>
                      </div>
                    </div>
                  </label>
                </Col>
                <Col md={4}>
                  <label className="radio mb-3 pr">
                    <input
                      type="radio"
                      className="one"
                      name="investmentDFundType"
                      value="Conservative Fund"
                      onClick={e => setRadioValue(e)}
                    />
                    <div className="flex-th cons">
                      <div
                        className={
                          formData.investmentDFundType ===
                            "Conservative Fund"
                            ? "current fund"
                            : "fund"
                        }>
                        <span />
                        <p>Conservative Fund</p>
                      </div>
                    </div>

                  </label>
                </Col>
                <Col md={4}>
                  <label className="radio mb-3 pr">

                    <input
                      type="radio"
                      className="one"
                      name="investmentDFundType"
                      value="First Home Buyers Strategy"
                      onClick={e => setRadioValue(e)}
                    />
                    <div className="flex-th First">
                      <div
                        className={
                          formData.investmentDFundType ===
                            "First Home Buyers Strategy"
                            ? "current fund"
                            : "fund"
                        }>
                        <span />
                        <p>First Home Buyers Strategy</p>
                      </div>
                    </div>

                  </label>
                </Col>


              </div>

              <div className="d-flex align-items-center mb-4 mt-2 row">
                <Col md={3}>
                  <label className="radio mb-3 pr">

                    <input
                      type="radio"
                      className="one"
                      name="investmentDFundType"
                      value="Balanced Strategy"
                      onClick={e => setRadioValue(e)}
                    />
                    <div className="flex-th Balance ">
                      <div
                        className={
                          formData.investmentDFundType === "Balanced Strategy"
                            ? "current fund"
                            : "fund"
                        }>
                        <span />
                        <p>Balanced Strategy</p>
                      </div>
                    </div>

                  </label>
                </Col>
                <Col md={3}>
                  <label className="radio mb-3 pr">

                    <input
                      type="radio"
                      className="one"
                      name="investmentDFundType"
                      value="Growth Fund"
                      onClick={e => setRadioValue(e)}
                    />
                    <div className="flex-th Growth">
                      <div
                        className={
                          formData.investmentDFundType === "Growth Fund"
                            ? "current fund"
                            : "fund"
                        }>
                        <span />
                        <p>Growth Fund</p>
                      </div>
                    </div>
                  </label>
                </Col>

                {/* <Col md={3}>
                  <label className="radio mb-3 pr">

                    <input
                      type="radio"
                      className="one"
                      name="investmentDFundType"
                      value="Climate Growth Strategy"
                      onClick={e => setRadioValue(e)}
                    />
                    <div className="flex-th ClimateGrowth">
                      <div
                        className={
                          formData.investmentDFundType ===
                            "Climate Growth Strategy"
                            ? "current fund"
                            : "fund"
                        }>
                        <span />
                        <p>Climate Growth Strategy</p>
                      </div>
                    </div>
                  </label>
                </Col> */}
                <Col md={3}>
                  <label className="radio mb-3">

                    <input
                      type="radio"
                      className="one"
                      name="investmentDFundType"
                      value="RetirementPlus Strategy"
                      onClick={e => setRadioValue(e)}
                    />
                    <div className="flex-th LegacyPath ">
                      <div
                        className={
                          formData.investmentDFundType ===
                            "RetirementPlus Strategy"
                            ? "current fund"
                            : "fund"
                        }>
                        <span />
                        <p>RetirementPlus Strategy</p>
                      </div>
                    </div>
                  </label>
                </Col>

                <Col md={12}>
                  <div className="footer">
                    <Button
                      type="button"
                      className="preview trans_btn"
                      onClick={previouss}>
                      <img src={backarrow} className="arrow_size" alt="arrow" />{" "}
                      Back
                    </Button>
                    <Button
                      type="button"
                      className="next grad_btn"
                      onClick={handleNext}>
                      Next
                      <img
                        src={nextarrow}
                        className="arrow_size"
                        alt="arrow"
                      />{" "}
                    </Button>
                  </div>
                </Col>
              </div>

              <span className="error optionerror b-0" />
            </Row>
            {/* <Row>
             
            </Row> */}
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Six;
