import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import logoimage from "../images/logo.png";
import nextarrow from "../images/nextarrow.png";
import backarrow from "../images/backarrow.png";
import infobtn from "../images/infobtn.png";
import $ from "jquery";
import Swal from "sweetalert2";
import InputMask from "react-input-mask";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
// import { createNewData } from "../reducers/Carrier";
// import {createNewDatazoo } from "../reducers/Datazoo";
// import { useDispatch, useSelector } from 'react-redux';
// import { validateIrd } from "./IRDValidatore";
import { datazoo } from "../components/Configuration";

import axios from "axios";
import { GoldFilled } from "@ant-design/icons";
const Providetax = ({ setForm, formData, navigation }) => {
  // const { irdNumber } = formData;
  // const createData = useSelector(state => state.Carrier)

  // const createData1 = useSelector(state => state.Datazoo)

  // const dispatch = useDispatch()

  //   console.log("createData",createData.SuccMessages)
  // console.log("createData1",createData1.SuccMessagess)
  // console.log("formData.addresscheck",formData.addresscheck=createData.SuccMessages)
  // formData.addresscheck=createData.SuccMessages
  // console.log("formData.idcheck",formData.idcheck=createData1.SuccMessagess)
  // formData.idcheck=createData1.SuccMessagess
  // if ((createData.SuccMessages== false)||(createData.SuccMessages==true)&&(createData.SuccMessages!= null)) {
  //   console.log("formData.addresscheck",formData.addresscheck=createData.SuccMessages)
  // }

  let city = sessionStorage.getItem("locality");
  let postal_code = sessionStorage.getItem("postal_code");
  let country = sessionStorage.getItem("country");
  let region = sessionStorage.getItem("sublocality_level_1");
  let route = sessionStorage.getItem("route");
  let street_number = sessionStorage.getItem("street_number");
  let subpremise = sessionStorage.getItem("subpremise");
  let addressLine1 = street_number + " " + route;

  const { entityCompanyOne, entityCompanyOne1, entityCompanyOne2 } = formData;
  // let CreateUser = () => {
  //   var postData = {
  //     firstname: formData.firstName,
  //     middlename: formData.middleName == null? "" :formData.middleName,
  //     lastname: formData.lastName == null ?"":formData.lastName,
  //     dateofbirth:formData.dateOfBirth,
  //     addressLine1: addressLine1 == "null null" ? formData.fullAddress: addressLine1,
  //     region: region,
  //     city: city,
  //     postalCode: postal_code,

  //   }
  //   //

  //   dispatch(createNewData(postData))

  // }

  //

  // let CreateUserdata = () => {
  //   let postData;
  //   if (formData.identificationType === "NZ Driver Licence") {
  //     postData = {
  //       firstname: formData.firstName,
  //       middlename: formData.middleName == null? "" :formData.middleName,
  //       lastname: formData.lastName == null ?"":formData.lastName,
  //       idexpiry: formData.expiryDate,
  //       idnumber: formData.identificationNumber,
  //       idversion: formData.version,
  //       dateOfBirth:formData.dateOfBirth,
  //       //gender: formData.gender,
  //       addressLine1:addressLine1 == "null null" ? formData.fullAddress: addressLine1,
  //       idtype: "DL",

  //     }
  //   } else if (formData.identificationType === "NZ Passport") {
  //     postData = {
  //       firstname: formData.firstName,
  //       middlename: formData.middleName == null ? "" :formData.middleName,
  //       lastname: formData.lastName == null ?"":formData.lastName,

  //       idexpiry: formData.expiryDate,

  //       dateofbirth:formData.dateOfBirth,
  //       // idversion: formData.version,
  //       idnumber: formData.identificationNumber,

  //      addressLine1:addressLine1 == "null null" ? formData.fullAddress: addressLine1,
  //      region: region,
  //      city: city,
  //      postalCode: postal_code,
  //       idtype: "PP",

  //     }
  //   }

  //   dispatch(createNewDatazoo(postData))

  // }
  // useEffect(() => {
  //   CreateUser()
  //   CreateUserdata()
  // }, [])

  const { previous, next, go } = navigation;

  // useEffect(() => {
  //   $(document).ready(function() {
  //     $("#hide").click(function() {
  //       $("#showmy").show();
  //       $("#hidemy").hide();
  //     });

  //     $("#show").click(function() {
  //       $("#hidemy").show();
  //       $("#showmy").hide();
  //     });
  //     $(".flagsave").click(function() {
  //       let country = $(
  //         ".counrtyselext #select_flag_button > span > span"
  //       ).text();
  //       localStorage.setItem("countryOfBirth", country);
  //     });
  //     $(document).ready(function() {
  //       let benIsNZCitizen = formData.benIsNZCitizen
  //         ? formData.benIsNZCitizen
  //         : "true";
  //       document.getElementsByClassName("toogles").value = benIsNZCitizen;
  //       $(".yesno").click(function() {
  //         benIsNZCitizen = $(this).val();
  //         formData.benIsNZCitizen = benIsNZCitizen;
  //       });
  //     });
  //     $(".flagsave").click(function() {
  //       let countryOfResidence = $(
  //         ".counrtyselext2 #select_flag_button > span > span"
  //       ).text();
  //       localStorage.setItem("countryOfResidence", countryOfResidence);
  //     });
  //   });
  //   $(".toogles").click(function() {
  //     $(".toogles").removeClass("actives");
  //     $(this).addClass("actives");
  //   });
  // });

  useEffect(() => {
    // amlcheckli();
    $("#input").on("wheel", function(e) {
      $(this).blur();
    });
  }, []);

  if (!formData.pir) {
    formData.pir = "Select";
  }

  function verifypir() {
    Swal.fire({
      html:
        "If you are unsure of your PIR rate, please visit this " +
        '<a href="https://www.ird.govt.nz/roles/portfolio-investment-entities/find-my-prescribed-investor-rate" target="_blank">IRD website</a>' +
        "  to determine your correct PIR"
    });
  }

  function removeError() {
    $(".error").html("");
  }

  function handleClick() {
    if (formData.is_additionalVoluntary !== "true") {
      formData.additionalVoluntaryFrequency = null;
      formData.additionalVoluntaryAmount = null;
      formData.primarySourceOfVoluntaryOther = null;
    }
    if (
      formData.is_lumpSumVoluntary === "false" &&
      formData.is_additionalVoluntary === "false"
    ) {
      formData.primarySourceOfVoluntary = null;
    }

    var btnValss = $(".postalAddressButton").hasClass("actives");
    if (formData.is_firstTimeKWS == null) {
      $(".alldata")
        .html("Please select Yes or No")
        .css("color", "red");
    } else if (
      (formData.kwsProvider == "" ||
        formData.kwsProvider == "undefined" ||
        formData.kwsProvider == null) &&
      formData.is_firstTimeKWS === "false"
    ) {
      $(".kwsProvider")
        .html("Please enter your current provider name")
        .css("color", "red");
    } else if (formData.is_lumpSumVoluntary == null) {
      $(".second_data")
        .html("Please select Yes or No")
        .css("color", "red");
    } else if (
      (formData.lumpSumVoluntaryAmount == "" ||
        formData.lumpSumVoluntaryAmount == null ||
        formData.lumpSumVoluntaryAmount == undefined) &&
      formData.is_lumpSumVoluntary === "true"
    ) {
      $(".lumpSumVoluntaryAmount")
        .html("Please enter voluntary  amount")
        .css("color", "red");
    } else if (
      (formData.primarySourceOfVoluntary == "Select" ||
        formData.primarySourceOfVoluntary == "" ||
        formData.primarySourceOfVoluntary == null ||
        formData.primarySourceOfVoluntary == undefined) &&
      formData.is_lumpSumVoluntary === "true"
    ) {
      $(".primarySourceOfVoluntary")
        .html("Please select source of contributions")
        .css("color", "red");
    } else if (formData.is_additionalVoluntary == null) {
      $(".allsecond")
        .html("Please select Yes or No")
        .css("color", "red");
    } else if (
      (formData.additionalVoluntaryFrequency == "Select Frequency" ||
        formData.additionalVoluntaryFrequency == "" ||
        formData.additionalVoluntaryFrequency == null ||
        formData.additionalVoluntaryFrequency == undefined) &&
      formData.is_additionalVoluntary === "true"
    ) {
      $(".additionalVoluntaryFrequency")
        .html("Please select additional voluntary frequency")
        .css("color", "red");
    } else if (
      (formData.additionalVoluntaryAmount == "" ||
        formData.additionalVoluntaryAmount == null ||
        formData.additionalVoluntaryAmount == undefined) &&
      formData.is_additionalVoluntary === "true"
    ) {
      $(".additionalVoluntaryAmount")
        .html("Please enter additional voluntary amount")
        .css("color", "red");
    } else if (
      (formData.primarySourceOfVoluntary == "Select" ||
        formData.primarySourceOfVoluntary == "" ||
        formData.primarySourceOfVoluntary == null ||
        formData.primarySourceOfVoluntary == undefined) &&
      formData.is_additionalVoluntary === "true"
    ) {
      $(".primarySourceOfVoluntary")
        .html("Please select source of contributions")
        .css("color", "red");
    } else if (
      formData.primarySourceOfVoluntary == "Other" &&
      (formData.primarySourceOfVoluntaryOther == "" ||
        formData.primarySourceOfVoluntaryOther == null ||
        formData.primarySourceOfVoluntaryOther == undefined)
    ) {
      $(".primarySourceOfVoluntaryOther")
        .html("Please provide details")
        .css("color", "red");
    } else if (
      formData.purposeOfJoinKWS == "" ||
      formData.purposeOfJoinKWS == "Select" ||
      formData.purposeOfJoinKWS == null ||
      formData.purposeOfJoinKWS == undefined
    ) {
      $(".purposeOfJoinKWS")
        .html("Please select your purpose ")
        .css("color", "red");
    } else if (
      formData.purposeOfJoinKWS == "Other" &&
      (formData.FpurposeOfJoinKWSOther == "" ||
        formData.FpurposeOfJoinKWSOther == null ||
        formData.FpurposeOfJoinKWSOther == undefined)
    ) {
      $(".FpurposeOfJoinKWSOther")
        .html("Please provide details")
        .css("color", "red");
    } else {
      go("Six");
    }
  }
  function setPass(e) {
    if (e.length !== 9) {
      $(".eerror")
        .html(
          "Your IRD number must be in the format 123-456-789. If your IRD number only has 2 digits at the start, please enter a 0 before the first digit. "
        )
        .css("color", "gray");
    }
  }
  const [options, setOption] = useState();

  function otherOption(event) {
    setOption(event.target.value);
  }

  function dhide() {
    document.body.classList.remove("dopen");
  }

  const yesNoPops = () => {
    Swal.fire({
      text:
        "By joining KiwiSaver for the first time, you will be required to make contributions from your pay for at least 12 months if you are an employee.",
      allowOutsideClick: false
    });
  };
  return (
    <div className="form provide_tax">
      <div className="headerlogo">
        <img src={logoimage} alt="" />
        <div className="headercontent">
          <h3 className="form_heading">
            Let us know how you'll use your Account
          </h3>
        </div>
      </div>
      <div className="body_content margin-25">
        <div>
          <Container>
            <Row>
              <Col lg={12}>
                <div className="col_innertext">
                  <label>Are you joining KiwiSaver for the first time?</label>
                </div>
              </Col>
              <Col lg={12}>
                <div className="button_group">
                  <div class="switch-field nospace">
                    <div className="check_YN_Bank swtich11">
                      <input
                        type="radio"
                        id="radio-one"
                        className="yesno"
                        name="is_firstTimeKWS"
                        value="true"
                        onChange={e => {
                          setForm(e);
                          removeError();
                          formData.kwsProvider = null;
                          yesNoPops();
                        }}
                      />
                      <label
                        for="radio-one"
                        className={`toogles postalAddressButton ${
                          // typeof formData.is_firstTimeKWS !== undefined ||
                          formData.is_firstTimeKWS === "true" ? "actives" : null
                        }`}
                        id="show">
                        Yes
                      </label>
                      <input
                        type="radio"
                        id="radio-two"
                        name="is_firstTimeKWS"
                        value="false"
                        onChange={e => {
                          setForm(e);
                          removeError();
                        }}
                      />
                      <label
                        for="radio-two"
                        className={`toogles postalAddressButtons ${
                          formData.is_firstTimeKWS === "false"
                            ? "actives"
                            : null
                        }`}
                        id="hide">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={12}>
                <span className="error yes_No alldata" />
              </Col>

              {formData.is_firstTimeKWS === "false" ? (
                <div style={{ width: "100%" }}>
                  <Col lg={12}>
                    <label>Who is your existing KiwiSaver Provider?</label>
                  </Col>
                  <Col md={6}>
                    <input
                      type="text"
                      onChange={e => {
                        setForm(e);
                        removeError(e);
                      }}
                      placeholder="Enter your current provider name"
                      name="kwsProvider"
                      value={formData.kwsProvider}
                    />
                    <span className="error kwsProvider" />
                  </Col>
                </div>
              ) : (
                ""
              )}
              <Col lg={12}>
                <div className="col_innertext">
                  <label>
                    Do you intend to make a lump sum voluntary contribution when
                    you open your KiwiSaver account?
                  </label>
                </div>
              </Col>

              <Col lg={12}>
                <div className="button_group">
                  <div class="switch-field nospace">
                    <div className="check_YN_Bank swtich11">
                      <input
                        type="radio"
                        id="radio-one1"
                        className="yesno"
                        name="is_lumpSumVoluntary"
                        value="true"
                        onChange={e => {
                          setForm(e);
                          removeError();
                        }}
                      />
                      <label
                        for="radio-one1"
                        className={`toogles postalAddressButton ${
                          // typeof formData.is_lumpSumVoluntary === "undefined" ||
                          formData.is_lumpSumVoluntary === "true"
                            ? "actives"
                            : null
                        }`}
                        id="show">
                        Yes
                      </label>
                      <input
                        type="radio"
                        id="radio-two1"
                        name="is_lumpSumVoluntary"
                        value="false"
                        onChange={e => {
                          setForm(e);
                          removeError();
                          formData.lumpSumVoluntaryAmount = null;
                        }}
                      />
                      <label
                        for="radio-two1"
                        className={`toogles postalAddressButtons ${
                          formData.is_lumpSumVoluntary === "false"
                            ? "actives"
                            : null
                        }`}
                        id="hide">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={12}>
                <span className="error yes_No second_data" />
              </Col>
              {formData.is_lumpSumVoluntary === "true" ? (
                <div style={{ width: "100%" }}>
                  <Col lg={12}>
                    <label>If yes, how much?</label>
                  </Col>
                  <Col md={6}>
                    <input
                      type="number"
                      onwheel="return false;"
                      onWheel={e => e.target.blur()}
                      name="lumpSumVoluntaryAmount"
                      placeholder="Enter $ amount"
                      onChange={e => {
                        setForm(e);
                        removeError(e);
                      }}
                      value={formData.lumpSumVoluntaryAmount}
                    />
                    <span className="error lumpSumVoluntaryAmount" />
                  </Col>
                </div>
              ) : (
                ""
              )}
              <Col lg={12}>
                <div className="col_innertext">
                  <label>
                    Do you intend on making additional voluntary contributions
                    to your KiwiSaver account?
                  </label>
                </div>
              </Col>

              <Col lg={12}>
                <div className="button_group">
                  <div class="switch-field nospace">
                    <div className="check_YN_Bank swtich11">
                      <input
                        type="radio"
                        id="radio-one2"
                        className="yesno"
                        name="is_additionalVoluntary"
                        value="true"
                        onChange={e => {
                          setForm(e);
                          removeError();
                        }}
                      />
                      <label
                        for="radio-one2"
                        className={`toogles postalAddressButton ${
                          // typeof formData.is_additionalVoluntary ==="undefined" ||
                          formData.is_additionalVoluntary === "true"
                            ? "actives"
                            : null
                        }`}
                        id="show">
                        Yes
                      </label>
                      <input
                        type="radio"
                        id="radio-two2"
                        name="is_additionalVoluntary"
                        value="false"
                        onChange={e => {
                          setForm(e);
                          removeError();
                        }}
                      />
                      <label
                        for="radio-two2"
                        className={`toogles postalAddressButtons ${
                          formData.is_additionalVoluntary === "false"
                            ? "actives"
                            : null
                        }`}
                        id="hide">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={12}>
                <span className="error yes_No allsecond" />
              </Col>
              <>
                {formData.is_additionalVoluntary === "true" ? (
                  <>
                    <div className="container">
                      <div className="row">
                        <Col lg={12}>
                          {" "}
                          <label>
                            How much and how often do you intend to make
                            additional voluntary contributions to your KiwiSaver
                            account?
                          </label>
                        </Col>
                        <Col md={6}>
                          <select
                            name="additionalVoluntaryFrequency"
                            onChange={e => {
                              setForm(e);
                              removeError();
                            }}
                            value={formData.additionalVoluntaryFrequency}>
                            <option>Select Frequency</option>
                            <option>Weekly</option>
                            <option>Fortnightly</option>
                            <option>Monthly</option>
                            <option>Six-monthly</option>
                            <option>Infrequently - Now & then </option>
                          </select>
                          <span className="error additionalVoluntaryFrequency" />
                        </Col>
                        <Col md={6}>
                          {" "}
                          <input
                            type="number"
                            name="additionalVoluntaryAmount"
                            placeholder="Enter $ amount"
                            onwheel="return false;"
                            onWheel={e => e.target.blur()}
                            onClick={dhide}
                            onChange={e => {
                              setForm(e);
                              removeError(e);
                            }}
                            value={formData.additionalVoluntaryAmount}
                          />
                          <span className="error additionalVoluntaryAmount" />
                        </Col>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
              <div>
                {formData.is_additionalVoluntary === "true" ||
                formData.is_lumpSumVoluntary === "true" ? (
                  <div style={{ width: "100%" }}>
                    <Col lg={12}>
                      {" "}
                      <label>
                        What is the primary source of voluntary contributions to
                        your KiwiSaver account?
                      </label>
                    </Col>
                    <Col md={6}>
                      <select
                        name="primarySourceOfVoluntary"
                        onChange={e => {
                          setForm(e);
                          removeError();
                          if (e.target.value != "Select") {
                            formData.primarySourceOfVoluntaryOther = null;
                          }
                        }}
                        value={formData.primarySourceOfVoluntary}>
                        <option>Select</option>
                        <option>Wages</option>
                        <option>Business earnings</option>
                        <option> Transfers from family</option>
                        <option>Rental income</option>
                        <option>Other</option>
                      </select>
                      <span className="error primarySourceOfVoluntary" />
                      <div>
                        {formData.primarySourceOfVoluntary == "Other" ? (
                          <>
                            <input
                              type="text"
                              onChange={e => {
                                setForm(e);
                                removeError();
                              }}
                              name="primarySourceOfVoluntaryOther"
                              value={formData.primarySourceOfVoluntaryOther}
                              placeholder="Please specify"
                            />
                            <span className="error primarySourceOfVoluntaryOther" />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <Col lg={12}>
                <div className="col_innertext">
                  <label>What is your purpose in joining KiwiSaver?</label>
                </div>
              </Col>
              <Col md={6}>
                <div className="col_innerSelect">
                  <select
                    name="purposeOfJoinKWS"
                    onChange={e => {
                      setForm(e);
                      removeError();
                      if (e.target.value != "Select") {
                        formData.FpurposeOfJoinKWSOther = null;
                      }
                    }}
                    value={
                      formData.purposeOfJoinKWS ? formData.purposeOfJoinKWS : ""
                    }>
                    <option>Select</option>
                    <option>Retirement</option>
                    <option>Income</option>
                    <option>Long term investment</option>
                    <option>
                      Future asset or lifestyle purchase (e.g. house, holiday,
                      education)
                    </option>
                    <option>Other</option>
                  </select>
                  <span className="error purposeOfJoinKWS" />
                </div>
                <div>
                  {formData.purposeOfJoinKWS == "Other" ? (
                    <>
                      <input
                        type="text"
                        name="FpurposeOfJoinKWSOther"
                        onChange={e => {
                          setForm(e);
                          removeError(e);
                        }}
                        value={formData.FpurposeOfJoinKWSOther}
                        placeholder="Please specify"
                      />
                      <span className="error FpurposeOfJoinKWSOther" />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="footer">
                  <Button
                    type="button"
                    className="preview trans_btn"
                    onClick={previous}>
                    <img src={backarrow} className="arrow_size" alt="arrow" />{" "}
                    Back
                  </Button>
                  {/* {(value === true || value === false)&& (value1 === true || value1 === false)
                  ? */}
                  <Button
                    type="button"
                    className="next grad_btn"
                    onClick={handleClick}>
                    Next
                    <img
                      src={nextarrow}
                      className="arrow_size"
                      alt="arrow"
                    />{" "}
                  </Button>
                  {/* :
                  <button
                  type="button"
                  className="next grad_btn"
                
                  >
                    {value}
                  Please Wait...
                  
                </button>

                  } */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Providetax;
