import axios from 'axios'
import { datazooapi } from '../components/Configuration'


// action type

// create user action type
export const CREATE_NEWUSERDATA_REQUEST = "CREATE_NEWUSERDATA_REQUEST";
export const CREATE_NEWUSERDATA_SUCCESS = "CREATE_NEWUSERDATA_SUCCESS";
export const CREATE_NEWUSERDATA_FAILURE = "CREATE_NEWUSERDATA_FAILURE";
export const FETCH_NEWUSERDATA_ERRORMESSAGES = "FETCH_NEWUSERDATA_ERRORMESSAGES";

// update user
export const UPDATE_NEWUSERDATA_REQUEST = "UPDATE_NEWUSERDATA_REQUEST";
export const UPDATE_NEWUSERDATA_SUCCESS = "UPDATE_NEWUSERDATA_SUCCESS";
export const UPDATE_NEWUSERDATA_FAILURE = "UPDATE_NEWUSERDATA_FAILURE";
export const UPDATE_NEWUSERDATA_ERRORMESSAGES = 'UPDATE_NEWUSERDATA_ERRORMESSAGES';
// initital state
const initialState = {
    loading: false,
    users: [],
    newUserData: {},
    error: '',
    errorMessages: null,
    SuccMessages:null,
    updateSuccMessage:null,
}
// actions
// create user action
export const createUserErrorMessages = (error) => {
    return {
      type: FETCH_NEWUSERDATA_ERRORMESSAGES,
      payload: error
    }
  }
export const createUserRequest = () => {
    return {
        type: CREATE_NEWUSERDATA_REQUEST,
    }
}
export const createUserSuccess = (success) => {
    return {
        type: CREATE_NEWUSERDATA_SUCCESS,
        payload: success
    }
}
export const createUserFailure = (error) => {
    return {
        type: CREATE_NEWUSERDATA_FAILURE,
        payload: error
    }
}

// reducer
export const userReducer = (state = initialState, action) => {
    switch(action.type) {
        
        // create user reducer
        case CREATE_NEWUSERDATA_REQUEST:
            return {
                ...state,
                loading: true,
                error: ''
            }
        case CREATE_NEWUSERDATA_SUCCESS:
        return {
            ...state,
            loading: false,
            SuccMessages:action.payload,
            error: ''
        }    
        case CREATE_NEWUSERDATA_FAILURE:
        return {
            ...state,
            loading: false,
             error: action.payload,
        } 
        case FETCH_NEWUSERDATA_ERRORMESSAGES:
    return {
      ...state,
      loading: false,
      errorMessages: action.payload
    }    
        default: return state
    }        
}
export default userReducer;

// create new user
export const createNewData = (postData) => {
    return (dispatch) => {
        dispatch(createUserRequest())
        axios.post(`${datazooapi}`, postData ,  {
        })
        .then((response) => {  
            const newUserData = response.data
            // console.log("Responce",newUserData);
            if (newUserData.error) {
                dispatch(createUserErrorMessages(newUserData.message))
            } {
                dispatch(createUserSuccess(newUserData.verified))
            }
        })
        .catch(error => dispatch(createUserFailure(error.message)) )
    }
}
 